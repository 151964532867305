import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Paper,
  FormLabel,
  useTheme,
  OutlinedInput,
} from "@mui/material";
import { AppRegistrationRounded as RegisterIcon } from "@mui/icons-material";
import { registerContibuteWebsiteUrl } from "../../store/data/actions";
import { Button } from "@material-tailwind/react";

export default function MyContributeWebsites() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(
    "success"
  );

  const [websiteUrl, setWebsiteUrl] = useState<string>("");

  const onRegisterCallback = ({
    status,
    returnData,
  }: {
    status: AlertColor | undefined;
    returnData: any;
  }) => {
    setAlertStatus(status);
    if (status === "success") {
      // setAlertContent(returnData.data.message);
      setAlertContent(
        `Thank you very much for your document submission! We typically take 24-48 hours to review your submission before we train it. To access the Training Docs and view your submission’s status, please apply for a PRO account at https://entheo.pro. For assistance, please email admin@mushgpt.com or just ask Magic Myc!`
      );
      setWebsiteUrl("");
    } else {
      setAlertContent(returnData.message);
    }
    setAlertOpen(true);
  };

  const onSubmit = () => {
    if (websiteUrl !== "") {
      dispatch(registerContibuteWebsiteUrl(websiteUrl, onRegisterCallback));
    } else {
      return false;
    }
  };

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const onChangeWebsiteUrl = (e: any) => {
    setWebsiteUrl(e.target.value);
  };
  return (
    <>
      <Box>
        <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
          {alertOpen && (
            <Alert
              variant={theme.palette.mode === "dark" ? "outlined" : "standard"}
              onClose={handleAlertClose}
              severity={alertStatus}
              sx={{ width: "100%" }}
            >
              <AlertTitle>
                {alertStatus &&
                  alertStatus[0].toUpperCase() + alertStatus.slice(1)}
              </AlertTitle>
              {alertContent}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              py: 3,
              px: 2,
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <FormLabel sx={{ marginBottom: "20px" }}>
              Register Web URLs
            </FormLabel>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                alignItems: "center",
                gap: 3,
              }}
            >
              <OutlinedInput
                fullWidth
                onChange={onChangeWebsiteUrl}
                value={websiteUrl}
                placeholder="Please input full URL"
              />
              <Button
                variant="outlined"
                className={`flex items-center gap-3 sm:w-auto ${
                  theme.palette.mode === "dark" && "bg-white"
                } w-full text-center justify-center`}
                disabled={websiteUrl?.length > 0 ? false : true}
                onClick={onSubmit}
              >
                <RegisterIcon />
                Register
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
