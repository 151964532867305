import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardHeader,
  FormControl,
  FormLabel,
  InputAdornment,
  CardContent,
  Stack,
  TextField,
  FormHelperText,
  Button,
  Grid,
  IconButton,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";

import { signIn } from "../../store/auth/actions";
import GoogleSignIn from "./GoogleSignIn";

interface Values {
  email: string;
  password: string;
  submit: null;
}

const initialValues: Values = {
  email: "",
  password: "",
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any, helpers: any): Promise<void> => {
      setLoading(true);
      dispatch(
        signIn(
          { email: values.email, password: values.password },
          navigate,
          helpers,
          setLoading
        )
      );
    },
  });

  useEffect(() => {
    localStorage.getItem("accessToken")
      ? navigate("/chat")
      : localStorage.removeItem("model");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box
        // container
        sx={{
          height: "100vh",
          backgroundImage: 'url("/images/mgpt-bg-compressed.webp")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flow-root",
        }}
        // alignItems="center"
        // justifyContent="center"
        // alignContent="center"
      >
        <Box
          // item
          sx={{
            position: "relative",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-60px",
              left: "calc(50% - 56px)",
            }}
          >
            <Link to="/">
              <img
                src="/images/M__logo.png"
                alt="MushGPT"
                className="max-w-[112px] max-h-[112px]"
              />
            </Link>
          </Box>
          <Card
            elevation={16}
            sx={{
              // width: '28vw',
              width: {
                xs: "90vw",
                sm: "490px",
              },
              // minWidth: {
              // 	md: '400px',
              // 	sm: 'cal(100% - 50px)',
              // },
              minHeight: "538px",
              borderRadius: "30px",
              padding: {
                sm: "33px 42px",
                xs: "33px 15px",
              },
              margin: "auto",
              marginTop: "100px",
            }}
          >
            <CardHeader sx={{ pb: 0 }} />
            <CardContent>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: "primary.main",
                    fontFamily: "Poppins !important",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "1.875rem !important",
                    lineHeight: 2.07,
                  }}
                >
                  Welcome Seeker
                </Typography>
              </Box>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  <FormControl>
                    <FormLabel sx={{ marginBottom: "8px" }}>
                      Email address
                    </FormLabel>
                    <TextField
                      autoFocus
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={{ marginBottom: "8px" }}>Password</FormLabel>
                    <OutlinedInput
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={formik.values.password}
                    />
                  </FormControl>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox />}
                        sx={{
                          "& .MuiTypography-root": {
                            color: "#8F92A1",
                            fontWeight: 400,
                            fontSize: "0.875rem",
                          },
                        }}
                        label="Remember me"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" sx={{ color: "#000000" }}>
                        <Link className="signup" to="/forget-password">
                          Forget password?
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
                {formik.errors.submit && (
                  <FormHelperText error sx={{ mt: 3 }}>
                    {formik.errors.submit as string}
                  </FormHelperText>
                )}
                <Button
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  sx={{
                    mt: 4,
                    borderRadius: "86px",
                    height: "60.65px",
                    mb: "10px",
                  }}
                  type="submit"
                  variant="contained"
                >
                  {loading ? <CircularProgress color="inherit" /> : "Sign In"}
                </Button>
              </form>
              <GoogleSignIn />
              <Typography
                variant="subtitle2"
                sx={{ color: "#929292", fontSize: "16px !important" }}
              >
                Don't have an account?&nbsp;
                <Link className="signup" to="/signup">
                  Sign Up
                </Link>
              </Typography>
            </CardContent>
            <Box
              sx={{
                position: "absolute",
                bottom: "-113px",
                left: "calc(50% - 85.5px)",
              }}
            >
              <img src="/images/image 7.png" alt="MushGPT" />
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
