import * as React from "react";
import { useState } from "react";
import type { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Input,
  Box,
  Typography,
  Paper,
  FormLabel,
  useTheme,
} from "@mui/material";
import { Button } from "@material-tailwind/react";
import { Upload } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import { handleUploadContributeFiles } from "../../store/data/actions";

export default function MyResearchDocs() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [uploadFiles, setUploadFiles] = useState<FileList | null>();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(
    "success"
  );

  const onChangeUploadFiles = (e: ChangeEvent<HTMLInputElement>): void => {
    setUploadFiles(e.target.files);
  };

  const onUploadCallBack = ({
    status,
    returnData,
  }: {
    status: AlertColor | undefined;
    returnData: any;
  }) => {
    setAlertStatus(status);
    if (status === "success") {
      // setAlertContent(returnData.data.message);
      if (returnData.data.message === "Only 0 files uploaded") {
        setAlertContent(`This file is already available.`);
        setAlertStatus("error");
      } else {
        setAlertContent(
          `Thank you very much for your document submission! We typically take 24-48 hours to review your submission before we train it. To access the Training Docs and view your submission’s status, please apply for a PRO account at https://entheo.pro. For assistance, please email admin@mushgpt.com or just ask Magic Myc!`
        );
      }
      setUploadFiles(null);
    } else {
      setAlertContent(returnData.message);
    }
    setAlertOpen(true);
  };

  const onSubmit = () => {
    if (uploadFiles?.length) {
      const data = new FormData();
      for (let i = 0; i < uploadFiles.length; i++) {
        const file = uploadFiles[i];
        data.append("books", file);
      }
      dispatch(handleUploadContributeFiles(data, onUploadCallBack));
    } else {
      return false;
    }
  };

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <Box>
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
        {alertOpen && (
          <Alert
            variant={theme.palette.mode === "dark" ? "outlined" : "standard"}
            onClose={handleAlertClose}
            severity={alertStatus}
            sx={{ width: "100%" }}
          >
            <AlertTitle>
              {alertStatus &&
                alertStatus[0].toUpperCase() + alertStatus.slice(1)}
            </AlertTitle>
            {alertContent}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            py: 3,
            px: 2,
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <FormLabel sx={{ marginBottom: "20px" }}>
            Upload document(s)
          </FormLabel>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              alignItems: "center",
              gap: 3,
            }}
          >
            <label
              aria-label="Upload Document"
              htmlFor="documents-upload"
              style={{ width: "100%", height: "55px" }}
            >
              <Input
                sx={{ display: "none" }}
                id="documents-upload"
                inputProps={{ multiple: true, accept: ".pdf,.doc,.docx,.txt" }}
                type="file"
                onChange={onChangeUploadFiles}
              />
              <Box
                sx={{
                  border: "solid 1px",
                  borderColor: grey[400],
                  height: "55px",
                  ":hover": { borderColor: grey[600] },
                  borderRadius: "10px",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  padding: "10px 20px",
                  gap: 4,
                }}
              >
                <div>
                  {uploadFiles?.length ? (
                    uploadFiles.length + " files"
                  ) : (
                    <Typography fontSize={24} color={grey[400]}>
                      Please select files
                    </Typography>
                  )}
                </div>
              </Box>
            </label>
            <Button
              variant="outlined"
              className={`flex items-center gap-3 sm:w-auto ${
                theme.palette.mode === "dark" && "bg-white"
              } w-full text-center justify-center`}
              disabled={uploadFiles?.length ? false : true}
              onClick={onSubmit}
            >
              <Upload />
              UPLOAD
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
